exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-blue-js": () => import("./../../../src/pages/blue.js" /* webpackChunkName: "component---src-pages-blue-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-choice-js": () => import("./../../../src/pages/choice.js" /* webpackChunkName: "component---src-pages-choice-js" */),
  "component---src-pages-collage-js": () => import("./../../../src/pages/collage.js" /* webpackChunkName: "component---src-pages-collage-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-database-js": () => import("./../../../src/pages/database.js" /* webpackChunkName: "component---src-pages-database-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-image-js": () => import("./../../../src/pages/image.js" /* webpackChunkName: "component---src-pages-image-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installation-cpl-js": () => import("./../../../src/pages/installation-cpl.js" /* webpackChunkName: "component---src-pages-installation-cpl-js" */),
  "component---src-pages-installation-cyberarts-js": () => import("./../../../src/pages/installation-cyberarts.js" /* webpackChunkName: "component---src-pages-installation-cyberarts-js" */),
  "component---src-pages-installation-hamburg-js": () => import("./../../../src/pages/installation-hamburg.js" /* webpackChunkName: "component---src-pages-installation-hamburg-js" */),
  "component---src-pages-installation-medialab-js": () => import("./../../../src/pages/installation-medialab.js" /* webpackChunkName: "component---src-pages-installation-medialab-js" */),
  "component---src-pages-installation-rotch-js": () => import("./../../../src/pages/installation-rotch.js" /* webpackChunkName: "component---src-pages-installation-rotch-js" */),
  "component---src-pages-installation-zurich-js": () => import("./../../../src/pages/installation-zurich.js" /* webpackChunkName: "component---src-pages-installation-zurich-js" */),
  "component---src-pages-installations-js": () => import("./../../../src/pages/installations.js" /* webpackChunkName: "component---src-pages-installations-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-red-js": () => import("./../../../src/pages/red.js" /* webpackChunkName: "component---src-pages-red-js" */),
  "component---src-pages-science-js": () => import("./../../../src/pages/science.js" /* webpackChunkName: "component---src-pages-science-js" */),
  "component---src-pages-someity-js": () => import("./../../../src/pages/someity.js" /* webpackChunkName: "component---src-pages-someity-js" */),
  "component---src-pages-sponsors-js": () => import("./../../../src/pages/sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-suggestions-js": () => import("./../../../src/pages/suggestions.js" /* webpackChunkName: "component---src-pages-suggestions-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */),
  "component---src-pages-word-cloud-js": () => import("./../../../src/pages/word-cloud.js" /* webpackChunkName: "component---src-pages-word-cloud-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-show-js": () => import("./../../../src/templates/show.js" /* webpackChunkName: "component---src-templates-show-js" */),
  "component---src-templates-specific-movie-info-js": () => import("./../../../src/templates/specificMovieInfo.js" /* webpackChunkName: "component---src-templates-specific-movie-info-js" */),
  "component---src-templates-word-cloud-movie-js": () => import("./../../../src/templates/wordCloudMovie.js" /* webpackChunkName: "component---src-templates-word-cloud-movie-js" */)
}

